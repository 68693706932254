@import '../../../src/shared/styles/variables.scss';
@import '../../../src/shared/styles/mixins.scss';

$main-viewport-width: pxToRem(1380);
$sidebar-width: pxToRem(290);
$column-gap: pxToRem(20);
$header-vertical-inset: -5000px; //  a random big negative number
$header-clip-value: $sidebar-width + $column-gap + 0.5rem;
$header-increHeight: 6.5rem;

.layout {
  padding: 0 1rem 12rem;
  @media (max-width: 900px) {
    padding: 0 1rem 5.5rem;
  }

  @media (max-width: 900px) {
    &.mobilePadding {
      margin-top: -13px;
      padding: 0;
    }
  }

  @include respond(tab-land) {
    display: grid;
    column-gap: $column-gap;
    grid-template-columns: 1fr $sidebar-width;
    grid-template-areas: 'center ...';
  }

  @include respond(big-desktop) {
    display: grid;
    grid-template-columns: $sidebar-width 1fr $sidebar-width;
    grid-template-areas: '... center ...';
    max-width: $main-viewport-width;
    margin: 0 auto;
  }

  .PageHeader {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -17px);
    padding: 1rem;
    z-index: 100;
    height: $header-height;
    background-color: $color-black100;

    @include respond(tab-land) {
      transform: translate(-50%, -15px);
      display: grid;
      column-gap: $column-gap;
      grid-template-columns: 1fr $sidebar-width;
      grid-template-areas: 'center right';
      clip-path: inset(
        $header-vertical-inset $header-clip-value $header-vertical-inset 0
      );
    }

    @include respond(big-desktop) {
      display: grid;
      column-gap: $column-gap;
      clip-path: inset($header-vertical-inset $header-clip-value);
      grid-template-columns: 18rem 1fr 18rem;
      grid-template-areas: 'left center right';
      max-width: $main-viewport-width;
      margin: 0 auto;
    }
  }

  .sidebars {
    display: none;
    position: fixed;
    left: 50%;
    // z-index: 100;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 1rem;

    @include respond(tab-land) {
      position: fixed;
      left: 50%;
      // z-index: 100;
      transform: translateX(-50%);
      width: 100%;
      padding: 0 1rem;

      display: grid;
      column-gap: $column-gap;
      grid-template-columns: 1fr $sidebar-width;
      grid-template-areas: 'center right';
    }

    @include respond(big-desktop) {
      display: grid;
      grid-template-columns: 18.6rem 1fr 18.6rem;
      grid-template-areas: 'left center right';
      max-width: $main-viewport-width;
      margin: 0 auto;
    }

    .left,
    .right {
      width: 100%;
      // z-index: 100;
      height: 100vh;
      overflow-y: auto;
      overflow-y: overlay;
      scrollbar-width: none;
      padding: 0 0.2rem;

      &:after {
        content: '';
        display: block;
        padding-bottom: 7rem;
      }
      &::-webkit-scrollbar {
        width: 0;
      }

      &:hover {
        &::-webkit-scrollbar-track {
          border-radius: 20px;
        }

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background-color: var(--color-aux6);
        }
      }

      @include respond(tab-land) {
        display: block;
      }
    }

    .left {
      display: none;
      grid-area: left;

      @include respond(big-desktop) {
        display: block;
      }
    }

    .right {
      grid-area: right;
    }
  }

  &.increHeight {
    height: $header-increHeight;
  }

  .PageHeader ~ .center {
    top: $header-height;
  }

  .PageHeader.increHeight ~ .center {
    top: $header-increHeight;
  }

  .center {
    width: 100%;
    overflow: hidden;
    grid-area: center;
    position: relative;
    // max-height: 60vh;
    // top: calc(3rem + 30px);
  }
}

.sideResponsive {
  display: flex;
  flex-direction: column;

  @include respond(tab-land) {
    display: grid;
  }

  .sidebars {
    order: -1;
    position: static;
    display: block;
    left: 0;
    transform: none;
    height: max-content;
    padding: 0;

    .left {
      display: block;
      height: max-content;

      &:after {
        content: '';
        display: block;
        padding-bottom: 1rem;
      }
    }

    @include respond(tab-land) {
      width: 100%;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 1rem;
      display: grid;
      column-gap: $column-gap;
      grid-template-columns: 1fr $sidebar-width;
      grid-template-areas: 'left right';

      @media (max-width: 1199px) {
        .left {
          display: none !important;
        }
      }
    }

    @include respond(big-desktop) {
      display: grid;
      grid-template-columns: 18.6rem 1fr 18.6rem;
      grid-template-areas: 'left center right';
      max-width: $main-viewport-width;
      margin: 0 auto;
    }
  }

  .right {
    display: none;
  }

  .center {
    position: static;
    z-index: 10;

    @media (max-width: 1199px) and (min-width: 900px) {
      margin-top: 4rem;
    }

    @include respond(big-desktop) {
      position: relative;
    }
  }

  .PageHeader {
    width: 100%;
    z-index: 100;
    background-color: $color-black100;
    position: static;
    left: 0;
    clip-path: none;
    transform: none;
    padding: 0;

    @include respond(tab-land) {
      width: 100%;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 1rem;
      display: grid;
      background-color: $color-black100;
      grid-template-columns: 1fr $sidebar-width;
      grid-template-areas: 'center right';
      clip-path: inset(
        $header-vertical-inset $header-clip-value $header-vertical-inset 0
      );
    }

    @include respond(big-desktop) {
      width: 100%;
      position: fixed;
      left: 50%;
      transform: translate(-50%, -17px);
      padding: 1rem;
      z-index: 100;
      height: $header-height;
      background-color: $color-black100;
      display: grid;
      column-gap: $column-gap;
      clip-path: inset($header-vertical-inset $header-clip-value);
      grid-template-columns: 18rem 1fr 18rem;
      grid-template-areas: 'left center right';
      max-width: $main-viewport-width;
      margin: 0 auto;
    }
  }
}
