.notFound {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 80vh;
    > p {
        color: var(--color-aux21);
        position: absolute;
        font-weight: 700;
        top: 44%;
        font-size: 18px;
        @media (max-width: 600px) {
            font-size: 13px;
        }
    }
    > button {
        position: absolute;
        margin-top: 58px;
    }
}
