@import "../../shared/styles/variables.scss";
@import "../../shared/styles/mixins.scss";

.nav {
  position: fixed;
  z-index: 1000;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 3rem;
  max-height: 5.625rem;
  background-color: $color-black80;
  font-family: $font-family;
  &__wrapper {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    @media (max-width: 600px) {
      justify-content: left;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    img {
      height: 1.875rem;
      cursor: pointer;
      object-fit: cover;
      margin: auto;
    }
  }
  &__btn {
    position: absolute;
    right: 20px;
    width: 163px;
    height: 40px;
    @media (max-width: 600px) {
      right: 0px;
      > button {
        font-size: var(--font-size-s);
      }
    }
  }
  &__logOutbtn {
    position: absolute;
    right: 20px;
    height: 40px;
    @media (max-width: 600px) {
      right: 0px;
      > button {
        font-size: var(--font-size-s);
      }
    }
  }
}

.authBtn {
  border: 1px solid #28f9da;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  font-weight: 700;
  font-size: 13px;
  color: #f2f2f2;
}
